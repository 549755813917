<template>
    <div class="fansPage">
        <div class="navbar">
            <div class="navbar_btn" @click="$router.go(-1)">
                <svg-icon iconClass="leftArrow"></svg-icon>
            </div>
            <div class="navbar_title">粉丝</div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {}
        }
    }
</script>

<style lang='scss' scoped>
    .fansPage {
        background: $whiteThree;
        height: 100vh;
        padding: 44px 0;

        .navbar {
            position: fixed;
            top: 0;
            background: $vermillion;
            width: 100%;
            color: $white1;
            height: 44px;
            font-size: 20px;
            padding: 0 16px;

            display: flex;
            align-items: center;

            .navbar_btn {
                margin-right: 28px;
            }

            .navbar_title {
                font-weight: 600;
            }
        }
    }
</style>